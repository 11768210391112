.contact {
    display: flexbox;
    justify-content: center;
    font-size: 1.5;
    text-align: center;
   
    
}

.flextitle {
    background-color: #021452;
    color: aliceblue;
    padding: 15px;
    margin: 0;
    
}
.contact-info {
    display: flexbox;
    justify-content: center;
    text-align: center;
    font-size: 1.3em;
    padding: 2%;
    
}