
.containerA {
    display: grid;
    grid-template-areas: 
    'header  header'
    'adventure  outdoor'
    'indoor  lounge'
    'kitchen  parking';
    gap: 0px;
    margin: 0px;
    font-size: 1em;
    margin: 0px;
    
}

.grid-itemA {
    grid-area: header;
    background-color: #021452;
    color: #f2f2f2;
    display: flexbox;
    justify-content: center;
    text-align: center;
    margin: 0px;
    
}
.grid-itemB {
    grid-area: adventure;
    padding: 1%;
    display: grid;
    justify-content: center;
    margin: 0px;
    padding-top: 0%;
    
}
.desc {
    background-color: #021452;
    color: white;
    padding: 1%;
    display: flex;
    justify-content: center;
    margin: 0px;
    
}
.desc p {
    margin: 0px;
}
.grid-itemC {
    grid-area: outdoor;
    display: grid;
    padding: 1%;
    justify-content: center;
    margin: 0px;
    padding-top: 0%;
    
}
.grid-itemD {
    grid-area: indoor;
    padding: 1%;
    display: grid;
    justify-content: center;
    margin: 0px;
    padding-top: 0%;
}
.grid-itemE {
    grid-area: lounge;
    display: grid;
    justify-content: center;
    padding: 1%;
    margin: 0px;
    padding-top: 0%;
}
.grid-itemF {
    grid-area: kitchen;
    padding: 1%;
    display: grid;
    justify-content: center;
    margin: 0px;
    padding-top: 0%;
}
.grid-itemG {
    grid-area: parking;
    display: grid;
    padding: 1%;
    justify-content: center;
    margin: 0px;
    padding-top: 0%;
}
.containerA img {
    width: 100%;
    height: 100%;
    
}

