.cookie-container {
    position: fixed;
  bottom: 0;
   left: 0;
   right: 0;
   background: #021452;
   color: aliceblue;
   padding: 10px 32px;
   transition: 400ms;
   box-shadow: 00 -2px 16px #02135200;
}

.cookie-btn {
    background: aliceblue;
    color: #000000;
    border: none;
    padding: 12px 26px;
    font-size: 1em;
    border-radius: 8px;
    margin-bottom: 2%;
    cursor: pointer;
    transition: 400ms;
    cursor: pointer;
    margin: 10px;
}