

.navi {
    padding: 10px;
    text-align: center;
    text-decoration: none;
    font-size: 1.4em;
    display: flex;
    margin: 0;
    color: black;
    font-family: Georgia, 'Times New Roman', Times, serif;
    font-weight: bold;
    

}


.navi:focus {
    
    color: rgb(255, 255, 255);
    
    background-color: #021452;
    
} 
.navi:hover {
    
    color: rgb(255, 255, 255);
    
    background-color: #021452;
    
} 

@media only screen and (max-width: 700px) {
    .navi  {
        padding: 0.1%;
        text-align: center;
        text-decoration: none;
        font-size: 1em;
        display: flex;
        margin: 0%;
        color: black;
        font-family: Georgia, 'Times New Roman', Times, serif;
        font-weight: bold;
    }
}