

.buttonLFL {
    background-color: #021452;
    color: white;
    padding: 5px;
    border: 1px solid #021452;
    border-radius: 20px;
    width: fit-content;
    height: fit-content;
    text-decoration: none;
   
}

a {
    text-decoration: none;
    color: #021452;
}

.grid-item1 {
    grid-area: header;
    background-color: #021452;
    color: #f2f2f2;
    padding: 1px;

}
.grid-item1 p {
    margin: 0;
}
.grid-item1 h2 {
    text-align: center;
   
    
}
.grid-item2 {
    grid-area: southside;
    padding: 0%;
    display: grid;
    justify-content: center;
    font-size: 1.2em;  
    margin-top: 0%;
    margin-left: 1%;
    
}
.grid-item2 p {
    margin: 0;
    padding: 0%;
}
.grid-item2 h3 {
    text-align: center;
    margin: 0% 0% 0%;
    padding: 5%;
    
}
.grid-item3 {
    grid-area: lfl;
;
    padding: 0%;
    display: grid;
    justify-content: center;
    font-size: 1.2em;
}
.grid-item3 p {
    margin: 0;
}
.grid-item3 h3 {
    text-align: center;
    
    margin: 0% 0% 0%;
    padding: 0%;

}

.grid-item4 {
    grid-area: lambethhomes;
    padding: 0%;
    padding-left: 1%;
    display: grid;
    
    font-size: 1.2em;
    
}
.grid-item4 p {
    margin: 0;
}
.grid-item4 h3 {
    text-align: center;
    
   
}
.grid-item5 {
    grid-area: jobhunt;
    font-size: 1.2em;
    
    padding: 1%;
    display: grid;
    justify-content: center;
}
.grid-item5 p {
    margin: 0;
}
.grid-item5 h3 {
    text-align: center;
    
}
.grid-item6 {
    grid-area: strikers;
    padding: 1%;
    display: grid;
    
    font-size: 1.2em;
    
    margin-bottom: 1%;
}
.grid-item6 p {
    margin: 0;
}
.grid-item6 h3 {
    text-align: center;
    
}
.grid-item7 {
    grid-area: adventure;
    font-size: 1.2em;
    
    padding: 1%;
    display: grid;
    
    margin-bottom: 1%;
}
.grid-item7 p {
    margin: 0;
}
.grid-item7 h3 {
    text-align: center;
    
}
.grid-item8 {
    grid-area: junior;
    font-size: 1.2em;
    
    padding: 1%;
    display: grid;
    
    margin-bottom: 1%;
}
.grid-item8 p {
    margin: 0;
}
.grid-item8 h3 {
    text-align: center;
    
}
.grid-item9 {
    grid-area: boxing;
    font-size: 1.2em;
    
    padding: 1%;
    display: grid;
    
    margin-bottom: 1%;
}
.grid-item9 p {
    margin: 0;
}
.grid-item9 h3 {
    text-align: center;
    
}
.grid-itemcycle {
    grid-area: cycle;
    font-size: 1.2em;
    
    padding: 1%;
    display: grid;
    
    margin-bottom: 1%;
}
.grid-itemcycle p {
    margin: 0;
}
.grid-itemcycle h3 {
    text-align: center;
    
}
.grid-itemcom {
    grid-area: com;
    font-size: 1.2em;
    
    padding: 1%;
    display: grid;
    
    margin-bottom: 1%;
}
.grid-itemcom p {
    margin: 0;
}
.grid-itemcom h3 {
    text-align: center;
    
}

.grid-container {
    display: grid;
    grid-template-areas: 
    'header header header header '
    'southside southside lfl lfl '
    'lambethhomes lambethhomes jobhunt jobhunt '
    'strikers strikers adventure adventure '
    'junior junior boxing boxing'
    'cycle cycle com com';
    gap: 0px;
  
}
.grid-container p {
    margin: 0;
}
img {
    width: 50%;
    height: 100%;
    
    
    
}
/* make this mobile friendly */
@media only screen and (max-width: 700px) {
    .buttonLFL {
        background-color: #021452;
        color: white;
        padding: 1%;
        border: 0.1% solid #021452;
        border-radius: 20px;
        width: fit-content;
        height: fit-content;
        text-decoration: none;
        margin: 1%;
       
    }
    a {
        text-decoration: none;
        color: #021452;
    }
    
    .grid-item1 {
        grid-area: header;
        background-color: #021452;
        color: #f2f2f2;
        padding: 2.5%;
        margin: 0%
    
    }
    .grid-container p {
        
        margin-block-start: 0em;
    margin-block-end: 0;
    
    }
    .grid-item1 h2 {
        text-align: center;
       
        
    }
    .grid-item2 {
        grid-area: southside;
        padding-top: 2.5%;
        padding-right: 0%;
        padding-left: 1%;
        display: grid;
        justify-content: center;
        font-size: 1em;
        width: 100%;
        height: 50%;
        margin: 0%;
        border:#021452 1px solid;
       
    }
    .grid-item2 h3 {
        text-align: center;
        margin: 0%;
        padding: 0%;
        padding-bottom: 5%;
        background-color: bisque;
    }
    .grid-item3 {
        grid-area: lfl;
        padding: 1.5%;
        display: grid;
        justify-content: center;
        font-size: 1em;
        border: #021452 1px solid;
        margin: 0%;
       
    }
    .grid-item3 h3 {
        text-align: center;
        margin: 0%;
        padding: 2%;
        background-color: bisque;
        
    }
    
    .grid-item4 {
        grid-area: lambethhomes;
        padding: 1%;
        display: grid;
        justify-content: center;
        font-size: 1em;
        border: #021452 1px solid;
        
        margin: 0%
    }
    .grid-item4 h3 {
        text-align: center;
        margin: 1%;
        padding: 1%;
        background-color: bisque;
       
    }
    .grid-item5 {
        grid-area: jobhunt;
        font-size: 1em;
        
        padding: 1%;
        display: grid;
        justify-content: center;
        border: #021452 1px solid;
    }
    .grid-item5 h3 {
        text-align: center;
        margin: 0%;
        padding: 1%;
        background-color: bisque;
    }
    .grid-item6 {
        grid-area: strikers;
        padding: 1%;
        display: grid;
        justify-content: center;
        font-size: 1em;
        border: #021452 1px solid;
        
        margin-bottom: 0.1%;
    }
    .grid-item6 h3 {
        text-align: center;
        margin: 1%;
        padding: 1%;
        background-color: bisque;
    }
    .grid-item7 {
        grid-area: adventure;
        font-size: 1em;
       
        padding: 1%;
        display: grid;
        justify-content: center;
        margin-bottom: 0.1%;
        border: #021452 1px solid;
       
    }
    .grid-item7 h3 {
        text-align: center;
        margin: 1%;
        padding: 1%;
        background-color: bisque;
    }
    .grid-item8 {
        grid-area: junior;
        font-size: 1em;
       
        padding: 1%;
        display: grid;
        justify-content: center;
        margin-bottom: 0.1%;
        border: #021452 1px solid;
       
    }
    .grid-item8 h3 {
        text-align: center;
        margin: 1%;
        padding: 1%;
        background-color: bisque;
    }
    .grid-item9 {
        grid-area: boxing;
        font-size: 1em;
       
        padding: 1%;
        display: grid;
        justify-content: center;
        margin-bottom: 0.1%;
        border: #021452 1px solid;
       
    }
    .grid-item9 h3 {
        text-align: center;
        margin: 1%;
        padding: 1%;
        background-color: bisque;
    }

    .grid-itemcycle {
        grid-area: cycle;
        font-size: 1em;
       
        padding: 1%;
        display: grid;
        justify-content: center;
        margin-bottom: 0.1%;
        border: #021452 1px solid;
       
    }
    .grid-itemcycle h3 {
        text-align: center;
        margin: 1%;
        padding: 1%;
        background-color: bisque;
    }

    .grid-itemcom {
        grid-area: community;
        font-size: 1em;
       
        padding: 1%;
        display: grid;
        justify-content: center;
        margin-bottom: 0.1%;
        border: #021452 1px solid;
       
    }
    .grid-itemcom h3 {
        text-align: center;
        margin: 1%;
        padding: 1%;
        background-color: bisque;
    }
    
    .grid-container {
        display: inline;
        grid-template-areas: 
        'header header header header '
        'southside southside lfl lfl '
        'lambethhomes lambethhomes jobhunt jobhunt '
        'strikers strikers adventure adventure '
        'junior junior boxing boxing'
        'cycle cycle community community';
        gap: 0px;
    }
    .grid-container p {
        
        margin-block-start: 0em;
    margin-block-end: 0;
    
    }
    img {
        width: 100%;
        height: 100%;
        
        
        
    }
}