
* {
        margin: 0;
}
.titlebackground {
   background-image: url(./playground1.JPG);
   
   background-size: cover;
    background-repeat: no-repeat;
   
    height: 100vh;
    
}
.soc-logo {
        display: flex;
        justify-content: center;
        gap: 0%;
        margin: 0%;
        padding: 10%;
        padding-left: 25%;
}

.home-title {
    
        text-align: center;
        color: #000000;
        font-size: 3em;   
        font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif; 
        margin-top: 0;
        padding-top: 10%;
        font-weight: bold;
       
    
}
.img {
        width: 22%;
        height: 90%;
        align-items: center;
        justify-content: center;
       
}



@media only screen and (max-width: 800px) {
        .home-title {
    
                text-align: center;
                color: #000000;
                font-size: 2em;   
                font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif; 
                margin: 0;
                padding-top: 30%;
                font-weight: bold;
            
        }
    }