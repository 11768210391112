.grid-item11 {
    grid-area: header;
    background-color: #021452;
    color: #f2f2f2;
    padding: 20px;
    display: flex;
    justify-content: center;
    margin: 0;
    font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    

}
.grid-item11 h2 {
    text-align: center;
    
}
.grid-item22 {
    grid-area: paragraph;
    padding: 8px;
    display: grid;
    justify-content: center;
    margin:50px;
    padding: 80px;
    
    font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    font-size: 1.2em;
    border: 1px solid #021452;
    
    
   
}



.grid-item33 {
    grid-area: images;
    
    padding: 8px;
    display: flex;
    justify-content: center;
    margin: 0px;
   
}
.grid-item44 {
    grid-area: images2;
    padding: 8px;
    display: flex;
    justify-content: center;
    margin: 0px;
   
}
.grid-item55 {
    grid-area: images3;
    padding: 8px;
    display: flex;
    justify-content: center;
    margin: 0px;
   
}
.grid-item66 {
    grid-area: images4;
    padding: 8px;
    display: flex;
    justify-content: center;
    margin: 0px;
    
   
}




.grid-container1 {
    display: flexbox;
    grid-template-areas: 
    'header header header header '
    'paragraph paragraph paragraph paragraph'
    'images images images images'
    'images3 images3 images3 images3'
    'images2 images2 images2 images2'
    'images4 images4 images4 images4';
    gap: 0px;
}
.grid-container1 img {
    width: 25%;
    height: 25%;
    
}


@media only screen and (max-width: 700px) {
    .grid-item22 {
        grid-area: paragraph;
        padding: 1%;
        display: grid;
        justify-content: center;
        margin:5%;
        padding: 3%;
        
        font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
        font-size: 1em;
        border: 1px solid #021452;
    }
    .grid-container1 img {
        width: 50%;
        height: 50%;
        
    }
}